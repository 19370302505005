<template>
  <v-card flat >

    <reloj :offsetTop="offsetTop"/>

    <router-view :secciones="secciones" :rrss="rrss" />

    <v-slide-y-transition appear>
      <inscribite-section v-show="delayed" />
    </v-slide-y-transition>

    <v-slide-y-transition appear>
      <contacto-r-r-s-s v-show="delayed" :secciones="secciones" :rrss="rrss"/>
    </v-slide-y-transition>

    <news-dialog />

    <v-slide-y-transition appear>
      <footer-component v-show="delayed"/>
    </v-slide-y-transition>

  </v-card>
</template>

<script>
export default {
  name: 'MainLayout',
  components: {
    InscribiteSection: () => import('@/views/Layout/InscribiteSection'),
    ContactoRRSS: () => import('@/views/Layout/ContactoRRSS'),
    FooterComponent: () => import('@/views/Layout/Footer'),
    NewsDialog: () => import('@/views/Layout/NewsDialog'),
    Reloj: () => import('@/views/components/Reloj')
  },
  props: {
    secciones: {
      type: Array,
      default: () => []
    },
    rrss: {
      type: Array,
      default: () => []
    },
    offsetTop: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      delayed: false
    }
  },
  mounted() {
    setTimeout(() => this.delayed = true, 350)
  }
}
</script>
